import React from "react";

function SvgArrowDown({ title = null, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      css={`
        color: ${props.color};
        width: ${props.size};
        height: ${props.size};
        vertical-align: text-top;
      `}
      data-pka-anchor="icon"
      focusable={false}
      {...props}
    >
      {title ? <title>{title}</title> : null}
      <path
        d="M10.514 17.293c.82.82 2.151.82 2.972 0L22.5 8.279 20.271 6.05l-8.27 8.272L3.728 6.05 1.5 8.279l9.014 9.014z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgArrowDown;
