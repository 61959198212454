import React from "react";

const SvgSort = ({ title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    css={`
      color: ${props.color};
      width: ${props.size};
      height: ${props.size};
      vertical-align: text-top;
    `}
    data-pka-anchor="icon"
    focusable={false}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.417 7.6l1.178-1.177L6.672 2.5 2.75 6.423 3.928 7.6 5.84 5.69v9.655h1.667V5.69l1.91 1.91zM16.083 13.09L14.173 15V5.345h-1.667V15l-1.911-1.91-1.178 1.178 3.922 3.922 3.923-3.923-1.179-1.178z"
      fill="currentColor"
    />
  </svg>
);

export default SvgSort;
